import React from 'react';
import './CandleAnimation.css'; // Import the CSS file

const Lamp = () => {
  return (
    <div className="container">
      <div className="candle">
        <div className="flame"></div>
      </div>
    </div>
  );
};

export default Lamp;