import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import HomePage from './Main';
import LampPage from './Lamp';


const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage/>,
    },
    {
        path: "/lamp",
        element: <LampPage/>,
      },
  ]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;